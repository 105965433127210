<template>
 <v-dialog
    v-model="show"
    persistent
    max-width="400"
  >
    <v-card>
      <v-toolbar color="#9B0A00" elevation="0">
          <v-toolbar-title class="text-white"> Delete Company </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        <p class="text-dark">
            When deleting this company all of the informations or data such as
          users, department,projects, and others under this company
          would be deleted.
        </p>
        <div class="text-white bg-danger p-2 rounded " v-if="status.warning">
          <small> *It seems that associated datas under company is still existing,
          please remove remaing datas </small>
        </div>
        </v-card-text >
      <v-card-actions class="border-top">
        <div class="text-right w-100 ">
          <v-btn
            class="text-secondary"
            @click="close"
            text >
            Cancel
          </v-btn>
          <v-btn
            color="#9B0A00"
            text
            @click="remove" 
            :loading="loadings.btn_remove"
          >
            Delete
          </v-btn>
        </div>
        
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>

export default {
 props: ['dialog', 'company', 'admin'],
 data()
 {
   return {
     arrays : {
       company : null,
       errors: [],
     },
     status: {
       admin : false,
       warning: false
     },
     loadings: {
       btn_remove : false,
     },
   }
 },
 computed : {
   show : {
     get()
     {
       if(this.company)
       {
         this.populate()
       }
       return this.dialog
     },
     set(value)
     {
       this.$emit('input', value)
     }
   }
 },
 methods: {
   populate()
   {
     this.status.warning = false
     this.arrays.company = this.company
     if(this.admin)
     {
       this.status.admin = true
     }
   },
   remove()
   {
     let id = null
     if(this.status.admin)
     {
       id = this.arrays.company.id
     }else{
       id = this.arrays.company.company.id
     }
    this.loadings.btn_remove = true
      this.axios
        .delete("companies/" + id)
        .then( () => {
          this.show = false
          this.status.warning = false
          this.$emit('refresh', true)
        })
        .catch( (e) => {
          if(e.response.status == 400){
            this.arrays.errors = e.response.data.errors
          }
          if(e.response.status == 500)
          {
            this.status.warning = true
          }
        })
        .finally(() => (this.loadings.btn_remove = false));
    },
    close()
    {
      this.show = false
    }
 }
}
</script>