<template>
  <v-pagination
    v-if="count > 1"
    circle
    color="#0A009B"
    class="my-2"
    v-model="page"
    :length="count"
    @input="select"
    :value="page"
    total-visible="5"
  ></v-pagination>
</template>
<script type="text/javascript">
export default {
  props: { meta: Object, default: {} },
  data() {
    return {
      page: null,
      count: null,
      per_page: null,
      total: null,
    };
  },
  methods: {
    select(pagenumber) {
      this.$emit("page", pagenumber);
    },
    compute(meta) {
      if (meta) {
        let l = parseInt(meta.total / meta.per_page);
        let checkRemaing = meta.total % meta.per_page;
        if (checkRemaing) {
          this.count = l + 1;
        } else {
          this.count = l;
        }
        this.page = this.meta.page;
        
      }
    }
  },
  watch: {
    meta: {
      immediate: true,
      deep: true,
      handler(meta) {
        this.compute(meta);
      },
    },
  },
};
</script>
<style scoped>
.custom{
  box-shadow: none!important;
  border: thin solid rgba(0, 0, 0, 0.12)!important;
}
</style>
